import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from "@angular/forms";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";
import { UserProfileService } from '../../../core/services/user.service';
import { AuthenticationService } from '../../../core/services/auth.service';
import { ActivatedRoute, Router } from "@angular/router";
import { WebSocketService } from "../../../core/services/web-socket.service";
import Swal from 'sweetalert2';
import { BnNgIdleService } from 'bn-ng-idle';
import { $ } from 'protractor';
@Component({
  selector: 'app-marks-evaluator',
  templateUrl: './marks-evaluator.component.html',
  styleUrls: ['./marks-evaluator.component.scss']
})
@Input()
export class MarksEvaluatorComponent implements OnInit {

  // Demo  data
  marksFieldsJson = '';
  marksFields = {
    Q: [],
    Total: 0
  }
  marksForm: FormGroup;
  submitted = false;
  totalMarks: number = 0;
  subjectToken = '';
  isFreezed = false;
  @Input()
  minimumTimeCompleted
  elem;
  subjectCode;
  @Input() ansForm: FormGroup;
  // mainAPI = "valuation/";
  mainAPI = 'reValuation/';
  constructor(private fb: FormBuilder,
    private userService: UserProfileService,
    private route: ActivatedRoute,
    private router: Router,
    private bnIdle: BnNgIdleService,
    private webSocketService: WebSocketService,
    private authService: AuthenticationService) {

  }

  ngOnInit(): void {
    this.webSocketService
      .listen("logout")
      .subscribe((data) => this.authService.autoLogout(data));
    this.bnIdle.startWatching(300).subscribe((isTimedOut: boolean) => {
      if (isTimedOut) {
        Swal.fire({
          title: 'Logout due to Inactivity',
          html: 'Click Continue or Anywhere on the screen to continue.',
          confirmButtonText: 'Continue',
          timerProgressBar: true,
          timer: 10000,
        }).then((result) => {
          if (
            result.dismiss === Swal.DismissReason.timer
          ) {
            let rawValue = this.marksForm.getRawValue();
            const answers = {
              rawValue,
              isFreeze: false
            }
            this.userService
              .post(this.mainAPI+'answerPaper/' + this.subjectToken + '/' + this.subjectCode, answers)
              .subscribe(
                (data) => {
                  sessionStorage.setItem('isValuationEnded', 'true');
                  this.authService.logout();
                  this.router.navigate(['/']);
                },
                (error) => {
                }
              );
          }
        });
      } else {
        console.log(isTimedOut)
      }
    });
    this.elem = document.documentElement;
    this.route.params.subscribe(params => {
      this.subjectToken = params.subjectToken;
      this.subjectCode = params.selectedSubject;
      this.userService
        .get(this.mainAPI+'getQuestionPaper/' + this.subjectToken + '/' + this.subjectCode)
        .subscribe(
          (data) => {
            const marksObtained = data['marksObtained'];
            const questions = data['questions'];
            this.marksFields = questions;
            this.marksForm = this.generateMarksForm();
            this.onCreateGroupFormValueChange();
            if (marksObtained && marksObtained.Total)
              this.prefillData(marksObtained);
            if (data['isFreezed'])
              this.isFreezed = data['isFreezed'];
          },
          (error) => {
          }
        );
    });
    this.marksForm = this.generateMarksForm();
    this.onCreateGroupFormValueChange();
  }

  prefillData(saveDate) {
    this.marksForm.patchValue(saveDate);
  }
  onCreateGroupFormValueChange() {
    this.marksForm.valueChanges
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe(() => {
        let sum = 0;
        sum = this.getFormValue(this.marksForm);
        this.marksForm.get('Total').get('obtainMarks').patchValue(sum, { emitEvent: false });
      });
  }

  getFormValue(formGroup: FormGroup): any {
    let sum: number = 0;
    Object.entries(formGroup.controls).forEach(([item, value]) => {
      let qList: Array<number> = [];
      const abstractControl = formGroup.controls[item];
      let max: number = Number.parseInt(item.split('^')[1]);
      if (abstractControl instanceof FormGroup) {
        Object.keys(abstractControl.controls).forEach((item: any) => {
          let validValue = this.getFormItemValue(abstractControl.controls[item]);
          if (validValue !== null && validValue !== undefined) {
            if (item.includes('^C')) {
              sum += validValue;
              max -= 1;
            }
            else
              qList.push(validValue);
          }
        });
      }
      sum += qList.sort((n1, n2) => n1 < n2 ? 1 : -1)
        .slice(0, Math.min(max, qList.length))
        .reduce((n1, n2) => { return (n1 as number) + (n2 as number); }, 0);
    });
    return sum;
  }
  getFormItemValue(item): any {
    if (item instanceof FormControl) {
      if (item.valid && (/\d+/).test(item.value))
        return Number(item.value);
    }
    if (item instanceof FormGroup) {
      let cList: Array<number> = [];
      let sum: number = 0;
      let gList = new Map<number, number>();
      if (item.controls.hasOwnProperty('obtainMarks')) {
        let validValue = this.getFormItemValue(item.controls['obtainMarks']);
        if (validValue !== null && validValue !== undefined)
          cList.push(validValue);
      } else {
        Object.keys(item.controls).forEach((iC: any) => {
          let validValue = this.getFormItemValue(item.controls[iC]);
          if (validValue !== null && validValue !== undefined)
            if (iC.includes('^')) {
              let gp: number = Number.parseInt(iC.split('^')[1]);
              gList.set(gp, (gList.get(gp) || 0) + validValue);
            } else
              cList.push(validValue);
        });
      }

      sum = cList.length != 0
        ? cList.reduce((n1, n2) => n1 + n2, 0)
        : Math.max(...gList.values(), 0);

      return sum;
    }
  }
  SaveOrFreeze(data: FormData, $event: Event) {
    this.submitted = true;
    if (this.marksForm.invalid) {
      return;
    }
    let activeButton = document.activeElement.id;
    let ansData = this.ansForm.getRawValue();

    let rawValue = this.marksForm.getRawValue();

    if (activeButton == "submit-1") {
      const answers = {
        rawValue,
        ansData,
        isFreeze: false
      }
      Swal.fire({
        title: 'Are you sure you want to save?',
        text: 'You need to freeze the student for final submission',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#34c38f',
        cancelButtonColor: '#ff3d60',
        confirmButtonText: 'Yes, Save'
      }).then(result => {
        if (result.value) {
          this.userService
            .post(this.mainAPI+'answerPaper/' + this.subjectToken + '/' + this.subjectCode, answers)
            .subscribe(
              (data) => {

              },
              (error) => {
              }
            );
        }
      })
    }
    if (activeButton == "submit-2") {

      if (this.ansForm.invalid) {
        return;
      }

      const answers = {
        rawValue,
        isFreeze: true
      }
      Swal.fire({
        title: 'Are you sure you want to freeze?',
        text: 'You won\'t be able to change marks after this!',
        icon: 'error',
        showCancelButton: true,
        confirmButtonColor: '#ff3d60',
        cancelButtonColor: '#34c38f',
        confirmButtonText: 'Yes, Submit And freeze'
      }).then(result => {
        if (result.value) {
          this.userService
            .post(this.mainAPI+'answerPaper/' + this.subjectToken + '/' + this.subjectCode, answers)
            .subscribe(
              (data) => {
                sessionStorage.setItem('isValuationEnded', 'true');
                this.router.navigate(["/"]);
              },
              (error) => {
              }
            );
        }
      })
    }
  }

  AlertForSaveNotAllowed(marksForm, ansForm, minimumTimeCompleted) {
    const title = marksForm ? 'Please enter marks for all questions' : ansForm ? 'All Images are not checked' : !minimumTimeCompleted ? 'Time limit is 4 minutes' : '';
    Swal.fire({
      position: 'center',
      icon: 'error',
      title: title,
      showConfirmButton: false,
      timer: 2500
    })
  }


  generateMarksForm(): FormGroup {
    const baseForm = this.fb.group({});
    this.marksFields['Q'].forEach(field => {
      let name = field.name + '^' + field.minQue;
      if (!baseForm.contains(name))
        baseForm.addControl(name, this.generateMarksFormGroup(baseForm, field));
    });
    //baseForm.addControl("Total", new FormControl(""));
    baseForm.addControl("Total", new FormGroup({
      obtainMarks: new FormControl(""),
      mainMarks: new FormControl(this.marksFields['Total'])
    }));
    return baseForm;
  }

  changeNToNA(event, marksPath, item) {
    const key = event.keyCode || event.charCode;
    let isBackSpace = false;
    if (key == 8) isBackSpace = true;

    if (event.target.value == '0') {
      Swal.fire(
        '0 is Entered',
        'are you sure?',
        'question')
    }
    if (event.target.value == 'n' || event.target.value == 'N') {
      if (!isBackSpace)
        Swal.fire(
          'NA(Not Answered) is Entered',
          'are you sure?',
          'question')
      if (!marksPath.includes('SECTION 2')) {
        const na = {};
        const question = item.groupnumber !== undefined ? item.question + '^' + item.groupnumber : item.question;
        const questionObj = {};
        const changedItem = { ...item };
        changedItem['obtainMarks'] = isBackSpace ? '' : 'NA';
        questionObj[question] = changedItem;
        na[marksPath] = questionObj;

        this.marksForm.patchValue(na);
      } else {
        const marksArray = marksPath.split('.');
        const na = {};
        const questionObj = {};
        questionObj[marksArray[1]] = {};
        const qustion = item.groupnumber !== undefined ? item.question + '^' + item.groupnumber : item.question;
        const changedItem = { ...item };
        changedItem['obtainMarks'] = isBackSpace ? '' : 'NA';
        questionObj[marksArray[1]][qustion] = changedItem;
        na[marksArray[0]] = questionObj;
        this.marksForm.patchValue(na);
      }
    }
  }

  generateMarksFormGroup(baseForm: FormGroup, field): FormGroup {
    if (field.group) {
      const formGroup = this.fb.group({});
      field.group.forEach(item => {
        let name = item.state === 'C' ? item.question + '^' + item.state : item.groupnumber !== undefined ? item.question + '^' + item.groupnumber : item.question;
        formGroup.addControl(name, this.generateMarksFormGroup(formGroup, item));
      });
      return formGroup;
    } else {
      var name = field.groupnumber !== undefined ? field.question + '^' + field.groupnumber : field.question;
      baseForm.addControl(name, new FormGroup({
        obtainMarks: new FormControl("", [Validators.min(0), Validators.required, Validators.max(field.mainMarks), Validators.pattern(/^([nN][aA]|[0-9][.][5]|[0-9]+)$/)]),
        mainMarks: new FormControl(field.mainMarks)
      }));
    }
    return baseForm;
  }

  goBack() {
    if (!this.isFreezed) {
      Swal.fire({
        title: 'Are you sure you want to go back?',
        text: 'The data entered till now will be saved, please come back again for save and submit.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#34c38f',
        cancelButtonColor: '#ff3d60',
        confirmButtonText: 'Yes, go back'
      }).then(result => {
        if (result.value) {
          let rawValue = this.marksForm.getRawValue();
          let ansData = this.ansForm.getRawValue();
          const answers = {
            rawValue,
            ansData
          }
          this.userService
            .post(this.mainAPI+'answerPaper/' + this.subjectToken + '/' + this.subjectCode, answers)
            .subscribe(
              (data) => {
                sessionStorage.setItem('isValuationEnded', 'true');
                this.router.navigate(["/"]);
              },
              (error) => {

              }
            );
        }
      })
    } else {
      sessionStorage.setItem('isValuationEnded', 'true');
      this.router.navigate(["/"]);
    }
  }
}
