import { Auth } from './../models/auth';
import { AuthticateService } from '../account/auth/authentication/authentication.service';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';

@Injectable()
export class RoleGuardService implements CanActivate {
  userRole: string;
  constructor(
    private authService: AuthticateService,
    private router: Router
  ) { }

  canActivate(route: ActivatedRouteSnapshot): boolean {
    // this will be passed from the route config
    // on the data property
    const expectedRole = route.data.expectedRole;
    // this.user = JSON.parse(sessionStorage.getItem('user'));
    // this.authService.userRoles.subscribe(role => {
    //     this.userRole = role;
    // })
    // console.log("expectedRole",expectedRole)
    const user = this.authService.getAuthRole();
    // console.log(user)
    if(user){
    this.userRole = user.role;
    // console.log(this.authService.loggedIn())
    // decode the token to get its payload
    // const tokenPayload = decode(token);
    if (
      !this.authService.loggedIn() ||
      expectedRole.indexOf(this.userRole) < 0
    ) {
      // console.log(expectedRole.indexOf(this.userRole))
      this.router.navigate(['/']);
      return false;
    } else {
      return true;
    }
  }else{
    this.router.navigate(['/']);
      return false;
  }
}
}
