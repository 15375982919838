<footer class="footer">
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-6">
        2024 © Bead Pharmacy.
      </div>
      <div class="col-sm-6">
        <div class="text-sm-right d-none d-sm-block">
         <!-- <i class="mdi mdi-heart text-danger"></i> -->
        </div>
      </div>
    </div>
  </div>
</footer>
