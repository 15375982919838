<div>
    <h1>Faculty Dashboard</h1>
    <h3 *ngIf="mainAPI === 'reValuation/' || mainAPI === 'reValuation1/'">Revaluation for Mar 2024 Examination D-Pharma</h3>
    <h3 *ngIf="mainAPI === 'valuation/'">Valuation for Mar 2024 Examination D-Pharma</h3>
    <ngb-alert type="danger" *ngIf="error" [dismissible]="false">{{ error }}</ngb-alert>
    <select  class="form-control"
        (change)="selectSubjectSyllabus($event)">
        <option value="" selected>Select Syllabus Type</option>
        <option value="Old">Old Syllabus</option>
        <option value="New">New Syllabus</option>
    </select><br><br>
    <select *ngIf="selectedSyllabus" [(ngModel)]="selectedSubject" class="form-control"
        (change)="selectCurrentSubject($event)">
        <option value="">Select Subject</option>
        <option *ngFor="let subject of subjects" [value]="subject.subjectCode" [disabled]="!isSubjectActive(subject.subjectCode)">
            {{getSubjectName(subject.subjectCode)}} - {{subject.subjectCode}} <span style="color:red;font-weight: 900;">{{!isSubjectActive(subject.subjectCode) ? ' - (Valuation is Not Active For this Subject)' : ''}}</span>
        </option>
    </select><br><br>
    <button class="btn btn-primary" (click)="Evaluate()"
        *ngIf="!unfrezzedValuations && !isLatestCountMoreThen80(sortByDate(valuatedScriptCount)) && selectedSubject && !loading" [disabled]="countOfPapersOpen>0"><span
            style="font-size: 28px; font-family: sans-serif">Click to Valuation paper for
            {{selectedSubject}}</span></button>
    <button class="btn btn-primary" *ngIf="unfrezzedValuations && selectedSubject && !loading"
        (click)="viewEditvaluationPaper()"><span style="font-size: 28px; font-family: sans-serif">Click to continue with
            unfreezed script for
            {{selectedSubject}}</span></button><br><br>

    <br>
    <button class="btn btn-info" *ngIf="selectedSubject" (click)="viewScripts(selectedSubject)"><span
            style="font-size: 28px; font-family: sans-serif">View number of scripts for
            {{selectedSubject}}</span></button>
    <h2 *ngIf="!selectedSubject && selectedSyllabus && subjects.length == 0">Subjects are not Updated in the Faculty Page: Visit the link : www.portal.beadpharmacy.org and login using COLN-999</h2>

    <h3 [ngClass]="loading ? 'visible' : 'invisible'">Loading...<div class="spinner-border text-primary m-1"
            role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </h3>
    <div *ngIf="selectedSubject && selectedSyllabus">
        <p style="font-size: 20px;font-weight: 700;" *ngIf="totalAvaliableScriptCount">Total scripts avaliable :
            <span>{{totalAvaliableScriptCount}}</span> /
            Total Scripts Valuated : <span>{{totalvaluatedScriptCount}}</span></p>
        <p style="font-size: 20px;font-weight: 700;">Total scripts valuated by you for <span
                *ngFor="let subject of subjects;let last = last">{{subject.subjectCode}}<span *ngIf="!last">,
                </span></span>
            : {{totalScriptCount}}</p>
        <h3 *ngIf="isLatestCountMoreThen80(sortByDate(valuatedScriptCount))">Quota of 80 is complete for today</h3>

        <div>
            <table>
                <tr>
                    <th class="tableThTd">Sl No</th>
                    <th class="tableThTd">Subject Code</th>
                    <th class="tableThTd">Date</th>
                    <th class="tableThTd">Number Of Scripts valuated</th>
                </tr>
                <tr *ngFor="let item of sortByDate(valuatedScriptCount); let i = index">
                    <td class="tableThTd">{{i+1}}</td>
                    <td class="tableThTd">{{selectedSubject}}</td>
                    <td class="tableThTd">{{item.date}}</td>
                    <td class="tableThTd">{{item.scriptTotal}}</td>
                </tr>
            </table>
        </div>
    </div>
</div>