import { Component, OnInit } from "@angular/core";
import { UserProfileService } from "../../core/services/user.service";
import { AuthenticationService } from "../../core/services/auth.service";
import { Subscription } from "rxjs/Subscription";
import { ActivatedRoute, Router } from "@angular/router";
import * as moment from "moment";
import {
  SUBJECT_MAPPING,
  NEW_SYLLABUS,
  CURRENT_SUBJECT_ACTION_FOR_VALUATION,
  COMPLETED_SUBJECT_START_FOR_REVIEW,
} from "../utility/app.constants";

import { WebSocketService } from "../../core/services/web-socket.service";
@Component({
  selector: "app-facultyDashboard",
  templateUrl: "./facultyDashboard.component.html",
  styleUrls: ["./facultyDashboard.component.scss"],
})

/**
 * facultyDashboard Component
 */
export class FacultyDashboardComponent implements OnInit {
  private httpGetFaculty: Subscription;
  hallticketdata: any = "";
  userdetails: any;
  error;
  unfrezzedValuations = {};
  loading = false;
  subjectCode;
  isMoreThen80ForToday = false;
  selectedSubject = "";
  subjects = [];
  mainSubjects = [];
  valuations = [];
  valuatedScriptCount = [];
  totalScriptCount = 0;
  totalAvaliableScriptCount;
  totalvaluatedScriptCount;
  selectedSyllabus;
  ipAddress;
  // mainAPI = "valuation/";
  countOfPapersOpen = 0;
  mainAPI = 'reValuation/';
  constructor(
    private userService: UserProfileService,
    private authenticationService: AuthenticationService,
    private router: Router,
    private webSocketService: WebSocketService
  ) { }

  ngOnInit(): void {
    // this.webSocketService
    //   .listen("logout")
    //   .subscribe((data) => this.authenticationService.autoLogout(data));
    this.ipAddress = sessionStorage.getItem("ipAddress");
    const year = sessionStorage.getItem("year");
    this.userService
      .post("auth/updateValauationCenterIpAddress", {
        ipAddress: this.ipAddress,
      })
      .subscribe(
        (data: number) => {
          // this.totalScriptCount = data;
        },
        (error) => {
          this.error = "Error In getting total scripts";
        }
      );
    this.userService.get(this.mainAPI + "getSubjects?year=" + year).subscribe(
      (data: Object[]) => {
        this.mainSubjects = data;
        // this.selectedSubject = data[0]["subjectCode"];
        if (data && data[0] && data[0]["subjectCode"]) {
          this.userService
            .get(
              this.mainAPI + "getUnfrezedValuations/" + data[0]["subjectCode"]
            )
            .subscribe(
              (data: Object[]) => {
                this.unfrezzedValuations = data[0];
              },
              (error) => {
                this.error = "Error In getting subjects";
              }
            );
        }
      },
      (error) => {
        //console.log("error", error);
        this.error = "Error In getting valuation scripts";
      }
    );
  }

  selectCurrentSubject(event) {
    const subjectCode = event.target.value;
    this.userService
      .get("valuation/getAllCurrentScriptCount/" + subjectCode)
      .subscribe(
        (data: number) => {
          this.countOfPapersOpen = data;
          this.userService
            .get(this.mainAPI + "getUnfrezedValuations/" + subjectCode)
            .subscribe(
              (data: Object[]) => {
                this.unfrezzedValuations = data[0];
                this.userService
                  .get(this.mainAPI + "getValuations/" + this.selectedSubject)
                  .subscribe(
                    (data: Object[]) => {
                      this.valuatedScriptCount = data;
                      this.userService
                        .get(this.mainAPI + "getTotalScripts/" + this.selectedSubject)
                        .subscribe(
                          (data: number) => {
                            this.totalScriptCount = data;
                          },
                          (error) => {
                            this.error = "Error In getting total scripts";
                          }
                        );
                    },
                    (error) => {
                      this.error = "Error In getting valuation scripts";
                    }
                  );
              },
              (error) => {
                this.error = "Error In getting valuation scripts";
              }
            );
        },
        (error) => {
          this.error = "Error In getting Get All Current Script Count";
        }
      );
  }

  selectSubjectSyllabus(event) {
    this.subjects = this.mainSubjects.filter((x) => {
      if (event.target.value == "New") {
        return (
          NEW_SYLLABUS.includes(x.subjectCode) && event.target.value == "New"
        );
      } else {
        return (
          !NEW_SYLLABUS.includes(x.subjectCode) && event.target.value == "Old"
        );
      }
    });
    this.subjects = this.subjects.slice(0, 4);
    this.selectedSyllabus = event.target.value;
    this.selectedSubject = "";
  }

  isLatestCountMoreThen80(latestVal) {
    if (
      latestVal[0] &&
      moment(moment().format("YYYY-MM-DD")).isSame(latestVal[0].date)
    ) {
      return latestVal[0].scriptTotal > 80;
    } else {
      return false;
    }
  }

  getSubjectName(subjectCode) {
    return SUBJECT_MAPPING[subjectCode];
  }

  isSubjectActive(subject) {
    return CURRENT_SUBJECT_ACTION_FOR_VALUATION.includes(subject);
  }

  sortByDate(scripts) {
    return scripts.sort(
      (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
    );
  }

  getTime(date) {
    return moment(date).format("YYYY-MM-DD HH:mm:ss");
  }

  viewScripts(selectedSubject) {
    this.loading = true;
    this.userService
      .get(this.mainAPI + "getScriptsAvaliable/" + selectedSubject)
      .subscribe(
        (data) => {
          this.totalAvaliableScriptCount = data["totalScript"];
          this.totalvaluatedScriptCount = data["valuatedScripts"];
          this.loading = false;
        },
        (error) => {
          //console.log("error", error);
          this.error = "Error In getting scripts avaliable";
        }
      );
  }

  freezedScripts(val) {
    return val.filter((x) => {
      return x.freezeScript;
    }).length;
  }

  unfreezedScripts(val) {
    return val.filter((x) => {
      return !x.freezeScript;
    }).length;
  }

  viewEditvaluationPaper() {
    this.userService
      .post(
        this.mainAPI + "getValuationToken/" + this.selectedSubject,
        this.unfrezzedValuations
      )
      .subscribe(
        (data) => {
          this.router.navigate([
            `/pages/valuation/${data}/${this.selectedSubject}`,
          ]);
        },
        (error) => {
          //console.log("error", error);
          this.error = "Error In getting valuation key";
        }
      );
  }

  async Evaluate() {
    this.loading = true;
    if (COMPLETED_SUBJECT_START_FOR_REVIEW.includes(+this.selectedSubject)) {
      this.userService
        .get(this.mainAPI + "getReviewScript/" + this.selectedSubject)
        .subscribe(
          (data) => {
            this.router.navigate([
              `/pages/valuation/${data}/${this.selectedSubject}`,
            ]);
          },
          (error) => {
            this.error = "Error In adding subject as review script";
          }
        );
    } else {
      this.userService
        // .get(this.mainAPI + 'getSpecificValuationScript/' + this.selectedSubject)
        .get(this.mainAPI + "getValuationRandomValue/" + this.selectedSubject)
        .subscribe(
          (data) => {
            this.router.navigate([
              `/pages/valuation/${data}/${this.selectedSubject}`,
            ]);
          },
          (error) => {
            this.loading = false;
            this.error = "Error In adding subject as valuation script";
          }
        );
    }
  }
}
