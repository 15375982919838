import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/Subject';
import { Auth } from '../../../models/auth';
import { Router } from '@angular/router';
import { tokenNotExpired } from 'angular2-jwt';

@Injectable()
export class AuthticateService extends Subject<any> {
  authUser: Auth;
  public userRoles: Subject<any> = new Subject<any>();
  isNavBarCollapsed = new Subject<Boolean>();

  constructor(
    private router: Router) {
    super();
  }

  getAuthDeatils(): Auth {
    this.authUser = (this.authUser) ? this.authUser : new Auth(JSON.parse(sessionStorage.getItem('userDetails')));
    return this.authUser;
  }
  
  getAuthRole(){
   return JSON.parse(sessionStorage.getItem('userDetails'));
    
  }
  logout() {
    window.location.reload();
    // this.authUser = null;
    // console.log(this.authUser)
    if (this.authUser) {
      this.authUser.authenticated = false;
      this.userRoles.complete();
      super.next(this.authUser);
    }
    this.router.navigate(['/']);
    // location.reload();
  }
  loggedIn(): boolean {
    const token = sessionStorage.getItem('authToken');
    // console.log(token,tokenNotExpired('authToken', token));
    return tokenNotExpired('authToken', token);
  }
  isLoggedInFaculty() {
    const user = this.getAuthDeatils();
    if (user.role === 'DIGITAL_VALUATOR') { // examiner login
      return true;
    } else {
      return false;
    }
  }

}
