<form [formGroup]="marksForm" (ngSubmit)="SaveOrFreeze(marksForm?.value,$event)">
    <ng-template #marksList let-section="section" let-marksPath="marksPath">
        <ng-container *ngFor="let item of section">
            <ng-container *ngIf="item.group; else default;">
                <div *ngIf="item.name" class="d-flex justify-content-center" style="font-weight:900">{{item.name}}</div>
                <div *ngIf="item.minQue" class="d-flex justify-content-center">Min Questions - <span
                        style="color:red">{{item.minQue}}</span></div>
                <div class="row no-gutters">
                    <ng-container *ngTemplateOutlet="marksList; context: { 
                            section: item.group, 
                            marksPath: marksPath ? [marksPath, item.state == 'C' ? item.question+'^'+ item.state : item.groupnumber !== undefined ?  item.question+'^'+ item.groupnumber: item.question ].join('.') : item.name+'^'+item.minQue
                        }"></ng-container>
                </div>
            </ng-container>

            <ng-template #default>
                <div class="form-group col-12 g-0" style="margin: 2px;">
                    <div class="input-group input-group-sm">
                        <label for="item.question"
                            class="col-2 col-form-label col-form-label-sm text-center px-0 tooltip1"
                            style="    min-width: 25%;    font-size: 16px;">
                            {{item.question}}
                            <div class="left1">
                                <h3>{{item.question}}</h3>
                                <p style="font-size: 15px;">{{item.question}}) {{item.queText}}</p>
                                <i></i>
                            </div>
                        </label>
                        <input style="width: 50% !important;" autocomplete="off" type="text"
                            [formControl]="marksForm.get([marksPath, item.groupnumber !== undefined ?  item.question+'^'+ item.groupnumber: item.question].join('.')).get('obtainMarks')"
                            placeholder="" [name]="item.question.obtainMarks" maxlength="item.mainMarks"
                            (keyup)="changeNToNA($event, marksPath, item)" />

                        <div class="input-group-append">
                            <span class="input-group-text" [ngClass]="{ 
                                'ngc-invalid': marksForm.get([marksPath, item.groupnumber !== undefined ?  item.question+'^'+ item.groupnumber : item.question].join('.'))?.get('obtainMarks')?.invalid,
                                'ngc-valid': marksForm.get([marksPath, item.groupnumber !== undefined ?  item.question+'^'+ item.groupnumber : item.question].join('.'))?.get('obtainMarks')?.valid && marksForm.get([marksPath, item.groupnumber !== undefined ?  item.question+'^'+ item.groupnumber : item.question].join('.'))?.get('obtainMarks')?.value != null &&  marksForm.get([marksPath, item.groupnumber !== undefined ?  item.question+'^'+ item.groupnumber : item.question].join('.'))?.get('obtainMarks')?.value != '',
                                'ngc-validwithZ': marksForm.get([marksPath, item.groupnumber != undefined ?  item.question+'^'+ item.groupnumber : item.question].join('.'))?.get('obtainMarks')?.value == '0',
                                'ngc-validwithE': marksForm.get([marksPath, item.groupnumber != undefined ?  item.question+'^'+ item.groupnumber : item.question].join('.'))?.get('obtainMarks')?.value == null && marksForm.get([marksPath, item.groupnumber !== undefined ?  item.question+'^'+ item.groupnumber : item.question].join('.'))?.get('obtainMarks')?.value == ''                            
                                 }"><b>/ {{item.mainMarks}}</b></span>
                        </div>
                    </div>
                </div>
                <div class="justify-content-center col-12" *ngIf="item.Optional">OR</div>
            </ng-template>
        </ng-container>
    </ng-template>


    <ng-container *ngTemplateOutlet="marksList; context:{ section: marksFields?.Q }"></ng-container>
    <div class="row form-group  g-0 justify-content-md-center">
        <div class="input-group input-group-sm col-12" formGroupName="Total">

            <label for="Total" class="col-5 col-form-label col-form-label-sm text-center px-0"
                style="font-size: 25px;font-weight: 900;"> Total </label>
            <input name="Total" formControlName="obtainMarks" style="width: 30% !important;font-size: 20px;"
                type="number" [attr.disabled]="true" />
            <div class="input-group-append">
                <span class="input-group-text" id="basic-addon2"
                    style="font-size: 20px;"><b>/{{marksFields?.Total}}</b></span>
            </div>
        </div>
    </div>


    <div class="form-row justify-content-md-center"
        *ngIf="!isFreezed && !marksForm?.invalid && !ansForm?.invalid && minimumTimeCompleted">
        <div class="col-6 text-center w-75 save-button">
            <input type="submit" id="submit-1" value="Save" class="btn btn-sm btn-primary" />
        </div>
        <div class="col-6 text-center submit-button">
            <input type="submit" id="submit-2" value="Submit & Freeze" class="btn btn-sm btn-success" />
        </div>
    </div>
    <div class="form-row justify-content-md-center"
        *ngIf="!isFreezed &&(marksForm?.invalid || ansForm?.invalid || !minimumTimeCompleted)">
        <div class="col-6 text-center w-75 save-button">
            <input type="button" id="submit-3" value="Save"
                (click)="AlertForSaveNotAllowed(marksForm?.invalid, ansForm?.invalid, minimumTimeCompleted)"
                class="btn btn-sm btn-primary" />
        </div>
        <div class="col-6 text-center submit-button">
            <input type="button" id="submit-4" value="Submit & Freeze"
                (click)="AlertForSaveNotAllowed(marksForm?.invalid, ansForm?.invalid, minimumTimeCompleted)"
                class="btn btn-sm btn-success" />
        </div>
    </div>
    <div class="form-row justify-content-md-center">
        <div class="col-12 text-center w-75 back-button">
            <input type="button" (click)="goBack()" id="submit-1" value="Go Back" class="btn btn-sm btn-danger" />
        </div>
    </div>
</form>