import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { User } from '../models/auth.models';

@Injectable({ providedIn: 'root' })
export class UserProfileService {
    constructor(private http: HttpClient) { }

    getAll() {
        return this.http.get<User[]>(`/api/login`);
    }

    register(user: User) {
        return this.http.post(`/users/register`, user);
    }

    post(url, data) {
        return this.http.post(environment.url + url, data);
    }
    customPost(url, data) {
        return this.http.post(url, data);
    }
    put(url, data, id) {
        if (id != '')
            return this.http.put(environment.url + url + "/" + id, data);
        else
            return this.http.put(environment.url + url, data);
    }
    get(url) {
        return this.http.get(environment.url + url);
    }
    getQPAndAnswers(url, token) {
        return this.http.get(environment.url + url + '/'+token);
    }
}
