// backend url
export const SIGNUP_URL = "users/register";
export const USER_API = "users";
export const LOGIN_URL = "auth";

export const SUBJECT_MAPPING = {
  "310": "Pharmaceutics-I",
  "320": "Pharmaceutical Chemistry- I",
  "330": "Pharmacognosy",
  "340": "Biochemistry and Clinical Pathology",
  "350": "Human Anatomy and Physiology",
  "360": "Health Education and Community Pharmacy",
  "410": "Pharmaceutics-II",
  "420": "Pharmaceutical Chemistry – II",
  "430": "Pharmacology and Toxicology",
  "440": "Pharmaceutical Jurisprudence",
  "450": "Drug Store and Business Management",
  "460": "Hospital and Clinical Pharmacy",
  "510": "Pharmaceutics",
  "520": "Pharma Chemistry",
  "530": "Pharmacognosy",
  "540": "Human Anatomy & Physiology",
  "550": "Social Pharmacy",
  "610": "Pharmacology",
  "620": "Community Pharmacy and Management",
  "630": "Biochemistry and Clinical Pathology",
  "640": "Pharmacotherapeutics",
  "650": "Hospital and Clinical Pharmacy ",
  "660": "Pharmacy Law and Ethics",
};

export const NEW_SYLLABUS = [
  "510",
  "520",
  "530",
  "540",
  "550",
  "610",
  "620",
  "630",
  "640",
  "650",
  "660",
];

export const CURRENT_SUBJECT_ACTION_FOR_VALUATION = [
  "410",
  "420",
  "430",
  "440",
  "450",
  "460",
  "310",
  "320",
  "330",
  "340",
  "350",
  "360",
];

export const COMPLETED_SUBJECT_START_FOR_REVIEW = [];
