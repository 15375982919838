import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { latLng, tileLayer } from 'leaflet';
import { first } from 'rxjs/operators';
import { UserProfileService } from '../../core/services/user.service';
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})

/**
 * Dashboard Component
 */
export class DashboardComponent implements OnInit {

  term: any;
  constructor(public formBuilder: FormBuilder, private userService: UserProfileService) {
  }


  ngOnInit(): void {
  }


}
