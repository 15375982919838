import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../services/auth.service';
import { catchError } from 'rxjs/internal/operators/catchError';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(private authService: AuthenticationService) { }

    // intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    //     const currentUser = this.authService.currentUserValue;
    //     console.log("currentUser", currentUser);
    //     let local = JSON.parse(sessionStorage.getItem('authToken'));
    //     console.log("local", local);

    //     if (currentUser && currentUser.token) {
    //         request = request.clone({
    //             setHeaders: {
    //                 authorization: `Bearer ${currentUser.token}`
    //             }
    //         });
    //     } else if (local) {
    //         request = request.clone({
    //             setHeaders: {
    //                 authorization: `Bearer ${local}`
    //             }
    //         });
    //     }

    //     // return next.handle(request);
    //     return next.handle(request)
    //         .pipe(catchError(err => {
    //             if (err instanceof HttpErrorResponse) {
    //                 if (err.status === 401) {
    //                     //console.log('this should print your error!', err.error);
    //                 }
    //             }
    //         }));
    // }

    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        const currentUser = this.authService.currentUserValue;
        // console.log("currentUser", currentUser);
        let local = JSON.parse(sessionStorage.getItem('authToken'));
        // console.log("local", local);

        if (currentUser && currentUser.token) {
            request = request.clone({
                setHeaders: {
                    authorization: `Bearer ${currentUser.token}`
                }
            });
        } else if (local) {
            request = request.clone({
                setHeaders: {
                    authorization: `Bearer ${local}`
                }
            });
        }

        return next.handle(request)
            .pipe(
                catchError(err => {
                    //console.log(err)
                    if (err instanceof HttpErrorResponse) { console.log("aaaaaaaaaa") }
                    return new Observable<HttpEvent<any>>(err);
                })
            );
    }

}
