import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, Observable, pipe } from "rxjs";
import { map } from "rxjs/operators";

import { User } from "../models/auth.models";

import * as moment from 'moment';

import { environment } from "../../../environments/environment";

@Injectable({ providedIn: "root" })
export class AuthenticationService {
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;

  constructor(
    private http: HttpClient
  ) {
    this.currentUserSubject = new BehaviorSubject<User>(
      JSON.parse(sessionStorage.getItem("currentUser"))
    );
    this.currentUser = this.currentUserSubject.asObservable();
  }
  url = environment.url;
  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  login(phoneNumber: string, year: string, password: string) {
    let currentTime = moment();
    let roundedTime = currentTime.subtract(currentTime.seconds() % 5, 'seconds');
    const time = roundedTime.format('YYYY-MM-DD HH:mm:ss');
    sessionStorage.setItem("time", time);
    return this.http
      .post<any>(`${this.url}auth/signin`, {
        phoneNumber,
        password,
        year,
        time
      })
      .pipe(
        map((user) => {
          // login successful if there's a jwt token in the response
          if (user && user.user.id) {
            sessionStorage.setItem("id", JSON.stringify(user.user.id));
            sessionStorage.setItem("authToken", JSON.stringify(user.token));
            sessionStorage.setItem("userDetails", JSON.stringify(user.user));
            // this.currentUserSubject.next(user);
            return { ...user.user, isOtp: user.isOtp };
          }
        })
      );
  }

  
  autoLogout(data: any) {
    const time = sessionStorage.getItem("time");
    const userDetails = JSON.parse(sessionStorage.getItem("userDetails"));
    if (userDetails.phone == data.phoneNumber && time != data.time) {
      this.logout();
    }
  }

  loginSuccess(data) {
    sessionStorage.setItem("currentUser", JSON.stringify(data));
    this.currentUserSubject.next(data);
    return data;
  }

  signup(data) {
    return this.http.post<any>(`${this.url}auth/signup`, data).pipe(
      map((user) => {
        return user;
      })
    );
  }

  resetPassword(data) {
    return this.http.post<any>(`${this.url}auth/generateOpt`, data).pipe(
      map((user) => {
        return user;
      })
    );
  }

  validateOtp(data) {
    return this.http.post<any>(`${this.url}auth/validateOtp`, data).pipe(
      map((user) => {
        return user;
      })
    );
  }

  getIpAddress() {
    return this.http.get("https://api.my-ip.io/v2/ip.json").pipe(
      map((user) => {
        return user;
      })
    );
  }

  checkIpWhiteList(data) {
    return this.http.post<any>(`${this.url}auth/isIpWitelisted`, data).pipe(
      map((user) => {
        return user;
      })
    );
  }

  logout() {
    // remove user from local storage to log user out
    sessionStorage.clear();
    this.currentUserSubject.next(null);
    window.location.reload();
  }

  simpleLogout() {
    // remove user from local storage to log user out
    sessionStorage.clear();
  }
}
